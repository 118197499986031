/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import { PortableText } from "@portabletext/react";
import Layout from "../components/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import PortableTextComponent from "../components/serializers/portableTextComponents";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

const ResourceImage = (image) => {
  const resourceImage = getImage(image.image.asset);
  return (
    <GatsbyImage
      sx={{
        height: "auto",
      }}
      image={resourceImage}
    />
  );
};
const ReturnImage = (image) => {
  const theImage = getImage(image);
  console.log(theImage);
  return (
    <GatsbyImage
      sx={{
        height: "auto",
      }}
      image={theImage}
    />
  );
};
const ReturnResource = (data) => {
  const { resource } = data;
  const { resource_type } = resource;
  switch (resource_type) {
    case "video":
      return (
        resource.video && (
          <LiteYouTubeEmbed id={resource.video} params="autoplay" />
        )
      );
      break;
    case "brochure":
      return (
        resource._rawBrochure && (
          <div
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              sx={{
                variant: "buttons.primarySolid",
              }}
              target="_blank"
              href={resource?._rawBrochure?.asset?.url}
            >
              View or Download {resource.title}
            </a>
          </div>
        )
      );

      break;
    case "application-notes":
      return (
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <a
            sx={{
              variant: "buttons.primarySolid",
            }}
            target="_blank"
            href={resource._rawApplicationNotes.asset.url}
          >
            View or Download {resource.title}
          </a>
        </div>
      );
      break;
    case "testimonials-success-stories":
      return (
        resource._rawTestimonials && (
          <div
            sx={{
              padding: "60px 0px",
              borderTop: "thin solid lightGray",
              h2: {
                textAlign: "center",
                margin: "0px",
              },
            }}
          >
            <div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                sx={{
                  maxWidth: "75%",
                  li: {
                    display: "flex",
                    alignItems: "center",
                  },
                  ".carousel .control-next.control-arrow:before": {
                    borderLeft: "28px solid",
                    borderLeftColor: "primary",
                    borderTop: "28px solid transparent",
                    borderBottom: "28px solid transparent",
                  },
                  ".carousel .control-prev.control-arrow:before": {
                    borderRight: "28px solid",
                    borderRightColor: "primary",
                    borderTop: "28px solid transparent",
                    borderBottom: "28px solid transparent",
                  },
                  ".slider": {
                    alignItems: "center",
                    q: {
                      fontStyle: "italic",
                    },
                    "q::before": {
                      fontSize: "100px",
                      color: "primary",
                      height: "100px",
                      display: "block",
                    },
                    "q::after": {
                      fontSize: "100px",
                      color: "primary",
                      // height: "40px",
                      display: "block",
                    },
                  },
                }}
              >
                <Carousel
                  // autoPlay
                  infiniteLoop
                  showArrows
                  showStatus={false}
                  showIndicators={false}
                  transitionTime={3000}
                  interval={10000}
                >
                  {resource._rawTestimonials.map((testimonial, index) => (
                    <q
                      key={index}
                      sx={{
                        padding: "0px 60px",
                      }}
                    >
                      <div className="product-testimonial">
                        {testimonial.body && (
                          <PortableText
                            value={testimonial.body}
                            components={PortableTextComponent}
                          />
                        )}
                        <div
                          sx={{
                            fontSize: "14px",
                          }}
                        >
                          {testimonial.name && <div>- {testimonial.name} </div>}
                          {testimonial.title && <div>{testimonial.title} </div>}
                          {testimonial.image && (
                            <img src={testimonial.image?.asset?.url} />
                          )}
                        </div>
                      </div>
                    </q>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        )
      );
      break;
    case "pictures":
      return (
        resource.pictures && (
          <div>
            <ul
              sx={{
                margin: "0px 0px 0px 0px",
                padding: "0px 0px 0px 0px",
                "> li": {
                  "&:nth-child(even)": {
                    flexDirection: "row-reverse",
                    backgroundColor: "white",
                  },
                },
              }}
            >
              {resource.pictures.map((image, index) => (
                <li
                  key={index}
                  sx={{
                    listStyle: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "lightGray",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    className="product-image-description-image"
                    sx={{
                      width: ["100%", "50%", "50%"],
                    }}
                  >
                    {image.image && (
                      <ResourceImage image={image.image}></ResourceImage>
                    )}
                  </div>
                  <div
                    className="product-image-description-text"
                    sx={{
                      width: ["100%", "50%", "50%"],
                      padding: [
                        "20px 40px 40px 40px",
                        "20px 40px 20px 40px",
                        "20px 40px 20px 40px",
                      ],
                      boxSizing: "border-box",
                      fontSize: "20px",
                      height: "100%",
                    }}
                  >
                    {image._rawText && (
                      <PortableText
                        value={image._rawText}
                        components={PortableTextComponent}
                      />
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )
      );
      break;
    case "facts-figures":
      return (
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <a
            sx={{
              variant: "buttons.primarySolid",
            }}
            target="_blank"
            href={resource?._rawFactsFigures?.asset?.url}
          >
            View or Download {resource.title}
          </a>
        </div>
      );
      break;
    default:
      return null;
  }
};
const Resource = (props) => {
  const { resource } = props.data;
  const bgImage = convertToBgImage(
    props.data.heroImage.childImageSharp.gatsbyImageData
  );
  return (
    <Layout type="resource-index-outer default-outer">
      <SEO title={resource.title} lang="en" description=""></SEO>
      <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
        <Container>
          <div
            sx={{
              position: "relative",
              zIndex: "2",
              padding: "180px 0px 100px 0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1 sx={{ color: "white" }}>{resource.title}</h1>
          </div>
        </Container>
        <div
          sx={{
            zIndex: "1",
            position: "absolute",
            top: "0",
            left: "0",
            height: "100%",
            width: "100%",
            opacity: "0.7",
            background:
              "linear-gradient(to top, #0b5d57 0%, #000000 98%, #000000 100%)",
          }}
        ></div>
      </BackgroundImage>
      <Container>
        <div
          sx={{
            padding: "60px 0px",
          }}
        >
          <div
            sx={{
              width: "100%",
              maxWidth: "600px",
              margin: "0 auto",
              marginBottom: "60px",
              textAlign: "center",
              fontSize: "26px",
              color: "gray",
            }}
          >
            {resource.description}
          </div>
          <ReturnResource resource={resource}></ReturnResource>
        </div>
      </Container>
    </Layout>
  );
};

export default Resource;

export const ResourceQuery = graphql`
  query ResourceBySlug($slug: String!) {
    heroImage: file(name: { eq: "freedom" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    resource: sanityResource(slug: { current: { eq: $slug } }) {
      title
      resource_type
      slug {
        current
      }
      description
      _rawApplicationNotes(resolveReferences: { maxDepth: 10 })
      _rawBrochure(resolveReferences: { maxDepth: 10 })
      _rawIndustryApplication(resolveReferences: { maxDepth: 10 })
      _rawTestimonials(resolveReferences: { maxDepth: 10 })
      _rawFactsFigures(resolveReferences: { maxDepth: 10 })
      video
      pictures {
        image {
          asset {
            gatsbyImageData(width: 800, height: 600)
          }
        }
        _rawText
      }
    }
  }
`;
